import { useMutation, useQuery } from "@apollo/client"
import { navigate } from "gatsby"
import React, { useState } from "react"
import { Container } from "../../components/styled/ProductsStyled"
import { USER_ORDER } from "../../querys"

import axios from "axios"
import { toast } from "react-toastify"
import OrderInformation from "./components/order-information"
import OrderValidation from "./components/order-validation"
import SendToFedex from "./components/send-to-fedex"
import { AddFedexOrder, ModifyStatusOrder } from "../../mutations"

const OrderForm = ({ id }) => {

  console.log(id, 'ididid')
  const [page, setPage] = useState(1)
  const [pending, setPending] = useState(false)

  const defaultDataFedex = {
    spersonName: "Kage",
    sphoneNumber: "1234567890",
    scompanyName: "SSD",
    sstreetLine: "43 W 33rd St #202",
    scity: "New York",
    sstateCode: "NY",
    spostalCode: "10001",
    scountryCode: "US",
    rpersonName: "Kage",
    rphoneNumber: "4564567892",
    rcompanyName: "SSD",
    rstreetLine: "23F・24F Nagoya Lucent Tower",
    rcity: "Tokyo",
    rstateCode: "BC",
    rpostalCode: "451-8688",
    rcountryCode: "JP",
    paymentType: "SENDER",
    commoditiesDescription: "Textile",
    countryOfManufacture: "US",
    quantity: "1",
    quantityUnits: "PCS",
    amount: "100",
    currency: "USD",
    customsValueAmount: "100",
    customsValueCurrency: "USD",
    commoditiesUnitWeight: "LB",
    commoditiesUnitValue: "20",
    documentOnly: true,
    shippingDocumentType: "COMMERCIAL_INVOICE",
    stockType: "PAPER_LETTER",
    documentFormatType: "PDF",
    serviceType: "INTERNATIONAL_PRIORITY",
    packagingType: "YOUR_PACKAGING",
    pickupType: "USE_SCHEDULED_PICKUP",
    shippingChargesPayment: "SENDER",
    blockInsightVisibility: false,
    shipDateStamp: "2022-02-17",
    imageType: "PDF",
    labelStockType: "PAPER_85X11_TOP_HALF_LABEL",
    requestedUnitWeight: "LB",
    requestedUnitValue: "70",
    accountNumberValue: "740561073",
  }

  const { data: dataOrder, loading: queryLoading } = useQuery(USER_ORDER, {
    variables: { order_id: 1114 },
    onCompleted: () => {},
  })
  
  const [updateStatus] = useMutation(ModifyStatusOrder, {
    onCompleted: () => {},
  })
  const [addfedexOrder] = useMutation(AddFedexOrder, {
    onCompleted: () => {},
  })

  const handlerBack = () => {
    if (page === 1) return
    setPage(page - 1)
  }
  const handlerNext = () => {
    if (page === 3) return
    setPage(page + 1)
  }

  const handlerSendFedex = () => {
    setPending(true)
    axios
      .post(
        "https://takihyo-textile-admin.herokuapp.com/fedexorders/send",
        defaultDataFedex
      )
      .then(response => {
        const { transactionId, output } = response.data
        
        const {
          serviceCategory,
          serviceType,
          shipDatestamp,
          shipmentDocuments,
        } = output.transactionShipments[0]

        const dataInformation = {
          transactionId: transactionId,
          serviceCategory: serviceCategory,
          serviceType: serviceType,
          shipDatestamp: shipDatestamp,
          document1: {
            contentType: shipmentDocuments[0].contentType,
            trackingNumber: shipmentDocuments[0].trackingNumber,
            url: shipmentDocuments[0].url,
          },
          document2: {
            contentType: shipmentDocuments[1].contentType,
            trackingNumber: shipmentDocuments[1].trackingNumber,
            url: shipmentDocuments[1].url,
          },
        }

        addfedexOrder({
          variables: {
            input: {
              data: {
                orderhistory: id,
                dataFedex: JSON.stringify(output),
                transaction_id: dataInformation.transactionId,
                category: dataInformation.serviceCategory,
                service_type: dataInformation.serviceType,
                ship_date: dataInformation.shipDatestamp,
                Shipping_pdf: dataInformation.document1.url,
                Commercial_invoice_pdf: dataInformation.document2.url,
              },
            },
          },
        })
        toast.success(`Success! transaction number: ${transactionId}`)

        navigate("/orders")
      })
      .catch(error => {
        
        toast.error(`Error, please enter a valid address`)
      })
      .finally(() => {
        updateStatus({
          variables: {
            order_id: {
              where: {
                id: id,
              },
              data: {
                status: "Sent",
              },
            },
          },
        })
        setPending(false)
      })
  }

  return (
    <Container className="container">
      {dataOrder === undefined ? (
        ""
      ) : (
        <div className="order_form_title">
          <p>#{id + " " + dataOrder.orderhistories[0].companyName}</p>
          <a>Paid</a>
          <a>Unfulfilled</a>
          <a>Fedex</a>
        </div>
      )}
      {page === 1 && dataOrder && (
        <OrderValidation
          dataOrder={dataOrder.orderhistories[0]}
          page={page}
        ></OrderValidation>
      )}
      {page === 2 && (
        <OrderInformation
          dataOrder={dataOrder.orderhistories[0]}
          defaultDataFedex={defaultDataFedex}
          page={page}
        ></OrderInformation>
      )}
      {page === 3 && (
        <SendToFedex
          dataOrder={dataOrder.orderhistories[0]}
          defaultDataFedex={defaultDataFedex}
          page={page}
          pending={pending}
        ></SendToFedex>
      )}
      <div className="order_form_button">
        {page !== 1 && (
          <button className="order_form_button_change" onClick={handlerBack}>
            ◀ &nbsp; Back Step
          </button>
        )}
        {page !== 3 && (
          <button className="order_form_button_change" onClick={handlerNext}>
            Next Step&nbsp; ▶
          </button>
        )}
        {page === 3 && (
          <button className="order_form_button_send" onClick={handlerSendFedex}>
            Send Fedex
          </button>
        )}
      </div>
    </Container>
  )
}
export default OrderForm
